<template>
  <div class="ss-statistics-group-student">
    <GroupQuery :org-enabled="true"
                :big-area-enabled="false"
                :record-enabled="false"
                :cancel-enabled="false"
                :show-shop-type="true"
                :show-select-all-org="true"
                :downloading="downloading"
                @reset="onReset"
                @search="onSearch"
                @export="exportExcel"
                :truename_or_username_or_idcard_show="true"/>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             :scroll="{x: '100%'}"
             row-key="rowId"
             class="table small-cell-table">
      <template slot="is_participant" slot-scope="text">
        <span v-if="text" class="success">参与</span>
        <span v-else class="danger">未参与</span>
      </template>
      <template slot="is_pass" slot-scope="text">
        <span v-if="text" class="success">通过</span>
        <span v-else class="danger">未通过</span>
      </template>
      <template slot="pass_percentage" slot-scope="t, r">{{ r.pass_percentage }}</template>
      <template slot="action" slot-scope="text, record">
        <div class="table-cell-wrapper">
          <a-button type="link" size="small" @click="viewPersonDetail(record)">查询个人明细</a-button>
        </div>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { statisticsGroupStudentDetail } from '@/utils/Navs/StatisticsNavs';

import {
  getGroupStudentData,
  getGroupStudentDataDownload,
} from '@/api/statistics/Index';

import Pagination, { getPagination } from '@components/Pagination';

import GroupQuery from '@views/statistics/components/GroupQuery';
import Common from '@/utils/Common';

const columns = [
  {
    width: '35px',
    align: 'center',
    dataIndex: 'n',
    key: 'n',
    title: 'ID',
  },
  {
    width: '150px',
    align: 'center',
    title: '大区',
    dataIndex: 'regional_org_name',
    key: 'regional_org_name',
  },
  {
    width: '180px',
    align: 'center',
    title: '机构',
    dataIndex: 'org_name',
    key: 'org_name',
  },
  {
    width: '65px',
    align: 'center',
    title: '姓名',
    dataIndex: 'truename',
    key: 'truename',
  },
  {
    width: '95px',
    align: 'center',
    title: '电话',
    dataIndex: 'username',
    key: 'username',
  },
  {
    width: '145px',
    align: 'center',
    title: '身份证',
    dataIndex: 'idcard',
    key: 'idcard',
  },
  {
    width: '180px',
    align: 'center',
    title: '岗位',
    dataIndex: 'post_name',
    key: 'post_name',
  },
  {
    width: '120px',
    align: 'center',
    title: '是否封禁',
    dataIndex: 'is_ban',
    key: 'is_ban',
  },
  {
    width: '80px',
    align: 'center',
    title: '是否参与',
    dataIndex: 'is_participant',
    key: 'is_participant',
    slots: { title: 'is_participant' },
    prefix: true,
  },
  {
    width: '80px',
    align: 'center',
    title: '是否通过',
    dataIndex: 'is_pass',
    key: 'is_pass',
    slots: { title: 'is_pass' },
    prefix: true,
  },
  {
    width: '85px',
    align: 'center',
    title: '人次通过率',
    dataIndex: 'pass_percentage',
    key: 'pass_percentage',
    scopedSlots: { customRender: 'pass_percentage' },
    prefix: true,
  },
  {
    width: '80px',
    align: 'center',
    title: '次均时长(秒)',
    dataIndex: 'avg_time',
    key: 'avg_time',
    prefix: true,
  },
  {
    width: '80px',
    align: 'center',
    title: '参与次数',
    dataIndex: 'participant_num',
    key: 'participant_num',
    prefix: true,
  },
  {
    width: '80px',
    align: 'center',
    title: '通过次数',
    dataIndex: 'pass_num',
    key: 'pass_num',
    prefix: true,
  },
  {
    width: '85px',
    align: 'center',
    title: '未通过次数',
    dataIndex: 'no_pass_num',
    key: 'no_pass_num',
    prefix: true,
  },
  {
    width: '80px',
    align: 'center',
    title: '累计时长(秒)',
    dataIndex: 'sum_use_times',
    key: 'sum_use_times',
    prefix: true,
  },
  {
    width: '65px',
    align: 'center',
    title: '最高分',
    dataIndex: 'max_score',
    key: 'max_score',
    prefix: true,
  },
  {
    width: '90px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'StatisticsGroupStudent',
  components: { GroupQuery, Pagination },
  computed: {
    ...mapState({
      queries: (state) => state.Statistics.speechGroupQuery,
    }),
    columns() {
      return columns.map((i) => {
        const item = { ...i };
        if (item.title && item.prefix && this.groupName) {
          item.title = this.groupName + ' - ' + item.title;
        }
        return item;
      });
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      data: [],
      groupName: '',

      pagination: { ...getPagination(), showSizeChanger: false },
    };
  },
  created() {
    if (this.queries?.group && this.queries?.job && this.queries?.org) {
      this.onSearch();
    }
  },
  destroyed() {
  },
  methods: {
    exportExcel() {
      const params = this.getParams(
        this.pagination.current,
        this.pagination.pageSize,
        true,
      );

      if (!this.queries?.group) {
        return this.$message.info({ content: '请选择话术检核' });
      }

      if (!this.queries?.job) {
        return this.$message.info({ content: '请选择岗位' });
      }

      // if (!this.queries?.bigArea) {
      //   return this.$message.info({ content: '请选择大区' });
      // }

      if (!this.queries?.org) {
        return this.$message.info({ content: '请选择组织机构' });
      }

      this.downloading = true;
      getGroupStudentDataDownload(params)
      .then(() => (this.downloading = false))
      .catch(() => (this.downloading = false));
    },

    onReset() {
      this.data = [];
      this.groupName = '';
      this.pagination = { ...getPagination(), showSizeChanger: false };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.group) {
        return this.$message.info({ content: '请选择话术检核' });
      }

      if (!this.queries?.job) {
        return this.$message.info({ content: '请选择岗位' });
      }

      // if (!this.queries?.bigArea) {
      //   return this.$message.info({ content: '请选择大区' });
      // }

      if (!this.queries?.org) {
        return this.$message.info({ content: '请选择组织机构' });
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getGroupStudentData(params).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      this.groupName = this.queries.groupName || '';
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowId = idx + 1 + (page - 1) * pageSize;
        item.rowId = 's-' + item.uid;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
      };

      this.queries?.startDateTime && (params.start_time = this.queries.startDateTime);
      this.queries?.endDateTime && (params.end_time = this.queries.endDateTime);
      this.queries?.examType && (params.is_makeup_exam = this.queries.examType === 2 ? 1 : 0);
      this.queries?.group && (params.check_id = this.queries.group);
      // this.queries?.bigArea && (params.regional_org_id = this.queries.bigArea);
      this.queries?.org && (params.regional_org_ids = this.queries.org);
      this.queries?.job && (params.post_id = this.queries.job);
      this.queries?.truename_or_username_or_idcard && (params.truename_or_username_or_idcard = this.queries.truename_or_username_or_idcard);
      if (this.queries?.shopType) {
        params.store_state = this.queries.shopType.store_state || '';
        params.store_type = this.queries.shopType.store_type || '';
        params.operation_state = this.queries.shopType.operation_state || '';
        params.store_age = this.queries.shopType.store_age || '';
      }
      isDown && (params.down = 1);

      return params;
    },

    // 查询个人明细
    viewPersonDetail(item) {
      this.$store.commit('Statistics/updateStudentInfo', {
        id: item.uid,
        name: item.truename,
      });
      this.$router.push({
        path: statisticsGroupStudentDetail[0].path,
        query: {
          uid: item.uid,
          uname: item.truename,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-group-student {
  width: 100%;
}
</style>
