import { render, staticRenderFns } from "./SelectSkillType.vue?vue&type=template&id=025687a2&scoped=true&"
import script from "./SelectSkillType.vue?vue&type=script&lang=js&"
export * from "./SelectSkillType.vue?vue&type=script&lang=js&"
import style0 from "./SelectSkillType.vue?vue&type=style&index=0&id=025687a2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "025687a2",
  null
  
)

export default component.exports