import {stringify} from 'qs';
import {baseUrl} from '@/Config';
import store from '@/store/index';

import request from '@/utils/Request';
import { downloadFileExtra } from '@/utils/JsDownload';

/* 区域数据 */

// 话术查询-区域数据
export function getSkillAreaData(params) {
  return request({
    url: '/speechskill/statistics/skill/all',
    method: 'get',
    params,
  });
}

// 话术查询-区域数据-导出 excel
export function getSkillAreaDataDownload(params) {
  let url = '/speechskill/statistics/skill/all';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 话术查询-区域岗位数据
export function getSkillAreaPostData(params) {
  return request({
    url: '/speechskill/statistics/skill/allByPost',
    method: 'get',
    params,
  });
}

// 话术查询-区域岗位数据-导出 excel
export function getSkillAreaPostDataDownload(params) {
  let url = '/speechskill/statistics/skill/allByPost';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}


/* 经销商数据 */

// 话术查询-经销商数据
export function getSkillDistributorData(params) {
  return request({
    url: '/speechskill/statistics/skill/allByOrg',
    method: 'get',
    params,
  });
}

// 话术查询-经销商数据-导出 excel
export function getSkillDistributorDataDownload(params) {
  let url = '/speechskill/statistics/skill/allByOrg';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 话术查询-经销商岗位数据
export function getSkillDistributorPostData(params) {
  return request({
    url: '/speechskill/statistics/skill/allByOrgPost',
    method: 'get',
    params,
  });
}

// 话术查询-经销商岗位数据-导出 excel
export function getSkillDistributorPostDataDownload(params) {
  let url = '/speechskill/statistics/skill/allByOrgPost';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}


/* 学员数据 */

// 话术查询-学员记录数据
export function getSkillStudentData(params) {
  return request({
    url: '/speechskill/statistics/skill/allByStu',
    method: 'get',
    params,
  });
}

// 话术查询-学员记录数据-导出 excel
export function getSkillStudentDataDownload(params) {
  let url = '/speechskill/statistics/skill/allByStu';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}


/* 学员记录 */

// 话术查询-学员记录-(检核、练习、学习数据)
export function getSkillStudentRecordData(params) {
  return request({
    url: '/speechskill/Statistics/skill/allByStuPer',
    method: 'get',
    params,
  });
}

// 话术查询-学员记录-(检核、练习、学习数据)
export function getSkillStudentRecordDataDownload(params) {
  let url = '/speechskill/statistics/skill/allByStuPer';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}




/* 学员记录 */

// 话术查询-题目明细表
export function getTopicDetailData(params) {
  return request({
    url: '/speechskill/statistics/skill/skillDetail',
    method: 'get',
    params,
  });
}

// 话术查询-题目明细表
export function getTopicDetailDataDownload(params) {
  let url = '/speechskill/statistics/skill/skillDetail';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}
