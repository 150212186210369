<template>
  <div>
    <div class="flex flex-start flex-wrap">
      <div class="flex flex-start mr-20 mb-5">
        <a-tooltip :title="planHintText" class="flex-none mr-5">
          <a-icon type="info-circle"/>
          选择任务:
        </a-tooltip>
        <div>
          <select-plan v-model="planIds"
                       mode="multiple"
                       @change="selectPlan"/>
        </div>
      </div>

      <div class="flex flex-start mb-5">
        <a-tooltip :title="courseHintText" class="flex-none mr-5">
          <a-icon type="info-circle"/>
          查询课程:
        </a-tooltip>
        <div style="font-size: 0;">
          <select-course v-model="courseIds"
                         type="course"
                         @change="selectCourse"/>
        </div>
      </div>
    </div>

    <!--    <a-form-model-item v-if="showExam" :label="'查询考试'">-->
    <!--      <select-course :show-label="false" :default-selected-ids="examIds"-->
    <!--                     type="exam" class="cus-selection" @select="selectExam"/>-->
    <!--    </a-form-model-item>-->
    <!--    <a-form-model-item v-if="showCourseAndExam" :label="'查询课程或考试'">-->
    <!--      <select-course :show-label="false" :default-selected-ids="courseAndExamIds"-->
    <!--                     type="" class="cus-selection" @select="selectCourseAndExam"/>-->
    <!--    </a-form-model-item>-->

    <div class="flex flex-start flex-wrap">
      <div v-if="showOrg" class="flex flex-start mr-20 mb-5">
        <span class="flex-none mr-5">组织机构:</span>
        <select-org v-model="orgIds"
                    :show-select-all-org="showSelectAllOrg"
                    :disable-merge-to-parent-level="[2]"
                    class="cus-selection"
                    @change="selectOrg"/>
      </div>
      <div class="flex flex-start mr-20 mb-5">
        <span class="flex-none mr-5">店类型:</span>
        <SelectShopType v-model="orgParams"
                        :not-all="true"
                        :same-level="true"
                        class="select-org"
                        @change="selectOrgType"/>
      </div>
      <div v-if="showEndTime" class="flex flex-start mr-20 mb-5">
        <span class="flex-none mr-5">截止日期:</span>
        <a-date-picker v-model="endTime"
                       :default-picker-value="defaultValue"
                       @change="endTimeChanged"
                       :disabledDate="disabledDate"/>
      </div>
      <div v-if="showName" class="flex flex-start mr-20">
        <span class="flex-none mr-5">姓名/电话/身份证号:</span>
        <a-input v-model="name" placeholder="姓名/电话/身份证号" allow-clear @change="nameChanged"/>
      </div>
    </div>

    <div v-if="showJob" class="flex flex-start flex-wrap">
      <div class="flex flex-start mr-20 mb-5nt">
        <span class="flex-none mr-5">推送岗位类型:</span>
        <a-select v-model="autoJobType"
                  style="width: 80px;"
                  @change="jobTypeChange">
          <a-select-option :value="0">默认</a-select-option>
          <a-select-option :value="1">按任务</a-select-option>
          <a-select-option :value="2">按课程</a-select-option>
        </a-select>
      </div>
      <div class="flex flex-start mr-20 mb-5">
        <span class="flex-none mr-5">岗位:</span>
        <select-job v-model="jobIds"
                    class="cus-selection"
                    @select="selectJob"/>
      </div>
    </div>

    <div class="full-width flex flex-end">
      <a-button type="primary" class="mr-60" @click="search(false)">查询</a-button>
      <a-button class="mr-30" @click="reset">重置</a-button>
      <a-button type="primary"
                :loading="downloading"
                @click="exportExcel(false)">
        导出
      </a-button>
      <a-button v-if="showBack" type="danger" @click="cancel">返回</a-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapMutations, mapState } from 'vuex';

import { maxAllowableCourseCount } from '../config';

import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import SelectCourse from './SelectCourse.vue';
import SelectPlan from './SelectPlan.vue';
import SelectShopType from '@components/select/SelectShopType';

export default {
  name: 'ParticipationQuery',
  components: {
    SelectOrg,
    SelectShopType,
    SelectJob,
    SelectCourse,
    SelectPlan,
  },
  props: {
    showCourse: Boolean,
    showExam: Boolean,
    showCourseAndExam: Boolean,
    showOrg: Boolean,
    showJob: Boolean,
    showEndTime: Boolean,
    showName: Boolean,
    showPeopleList: Boolean,
    showBack: Boolean,
    showSelectAllOrg: Boolean,

    customBack: Boolean,
    downloading: Boolean,
    courseType: { type: String, default: '' },
  },
  computed: {
    ...mapState({
      queries: (state) => state.StatisticsParticipation.query,
      orgTree: (state) => state.Common.orgTree,
    }),
    placeholder() {
      switch (this.courseType) {
        case 'course':
          return '课程';
        case 'exam':
          return '考试';
        default:
          return '课程或考试';
      }
    },

    planHintText() {
      return `任务可多选，但对应的课程总数不能超过${maxAllowableCourseCount}`;
    },
    courseHintText() {
      return `最多同时查询${maxAllowableCourseCount}门课程`;
    },
  },
  watch: {
    queries(query) {
      this.init(query);
    },
    'queries.courseIds'(val, oldVal) {
      if (val !== oldVal) {
        this.setQuery({
          orgParams: this.$store.state.StatisticsParticipation.defaultQuery.orgParams,
        });
      }
    }
  },
  data() {
    return {
      maxAllowableCourseCount,

      peopleListId: undefined, // 人员表选中项 id
      autoJobType: 0,
      planJobIds: '',
      courseJobIds: '',
      planIds: '',
      courseIds: '',
      // examIds: '',
      // courseAndExamIds: '',
      orgIds: '',
      jobIds: '',
      endTime: undefined,
      name: '',
      orgParams: {},
      routePath: this.$route.path,
      defaultValue:
        this.$route.path.search('/index/statistics/participation/student') >= 0
          ? moment()
          : moment().add(-1, 'day'),
    };
  },
  created() {
    this.init(this.queries);
  },
  methods: {
    ...mapMutations({
      setQuery(commit, query) {
        commit('StatisticsParticipation/setQuery', query);
      },
      resetQuery(commit) {
        commit('StatisticsParticipation/resetQuery');
      },
    }),

    jobTypeChange(type) {
      const query = {
        autoJobType: type,
      };

      if (type === 0 && this.planIds || type === 1) {
        query.jobIds = this.planJobIds || '';
      }
      if (type === 0 && !this.planIds || type === 2) {
        query.jobIds = this.courseJobIds || '';
      }

      this.setQuery(query);
    },

    init(query = {}) {
      this.autoJobType = query.autoJobType || 0;
      this.planJobIds = query.planJobIds || '';
      this.courseJobIds = query.courseJobIds || '';
      this.planIds = query.planIds || '';
      this.peopleListId = query.peopleListId || undefined;
      this.courseIds = query.courseIds || '';
      // this.examIds = query.examIds || '';
      // this.courseAndExamIds = query.courseAndExamIds || '';
      this.orgIds = query.orgIds || '';
      this.jobIds = query.jobIds ? `${query.jobIds}` : '';
      this.endTime = query.endTime || undefined;
      if (this.$route.path.search('/index/statistics/participation/student') < 0) {
        if (this.endTime && this.endTime.isSame(moment(), 'days')) {
          this.endTime = moment().subtract(1, 'days');
          this.endTimeChanged();
        }
      }
      this.name = query.name || '';
      if (
        query.orgParams?.store_state === '' &&
        query.orgParams?.store_age === '' &&
        query.orgParams?.store_type === '' &&
        query.orgParams?.operation_state === ''
      ) {
        this.orgParams = { store_state: '1' };
      } else {
        this.orgParams =
          JSON.stringify(query.orgParams) === '{}'
            ? { store_state: '1' }
            : query.orgParams ?? {};
      }
    },

    combineField(arr, field) {
      if (!arr?.length) {
        return '';
      }
      return Array.from(new Set((arr.reduce(
        (prev, cur) => [(cur?.[field] || ''), prev].join(','),
        '',
      )).split(','))).filter((i) => !!i).join(',');
    },
    selectPlan(ids, list) {
      const query = {
        planIds: ids,
        planList: list,
        courseIds: this.combineField(list, 'course_ids'),
        planJobIds: this.combineField(list, 'post_ids'),
      };

      if ([0, 1].includes(this.autoJobType)) {
        query.jobIds = query.planJobIds;
      }

      this.setQuery(query);
    },
    selectCourse(ids, list) {
      const query = {
        courseIds: ids,
        courseList: list,
        courseJobIds: this.combineField(list, 'tags'),
      };

      if (!this.autoJobType && !this.planIds || this.autoJobType === 2) {
        query.jobIds = query.courseJobIds;
      }

      this.setQuery({
        ...query,
        examIds: ids || '',
        examList: list || [],
        courseAndExamIds: ids || '',
        courseAndExamList: list || [],
      });
    },
    // selectExam(event, list) {
    //   this.setQuery({
    //     examIds: event && event.length ? event.join(',') : '',
    //     examList: list?.length ? list : [],
    //   });
    // },
    // selectCourseAndExam(event, list) {
    //   this.setQuery({
    //     courseAndExamIds: event && event.length ? event.join(',') : '',
    //     courseAndExamList: list?.length ? list : [],
    //   });
    // },

    selectOrg(ids, list) {
      this.setQuery({
        orgIds: ids,
        orgList: list?.length ? list : [],
      });
    },
    selectOrgType(value) {
      this.setQuery({
        orgParams: value,
      });
    },
    selectJob(event, list) {
      this.setQuery({
        jobIds: event && event.length ? event.join(',') : '',
        jobList: list?.length ? list : [],
      });
    },

    endTimeChanged() {
      this.setQuery({
        endTime: this.endTime,
      });
    },
    nameChanged() {
      this.setQuery({
        name: this.name,
      });
    },

    check(hideToast) {
      if (this.showCourse && !this.courseIds) {
        if (!hideToast) {
          this.$message.info('请选择课程');
        }
        return false;
      }
      // if (this.showExam && !this.examIds) {
      //   if (!hideToast) {
      //     this.$message.info('请选择考试');
      //   }
      //   return false;
      // }
      // if (this.showCourseAndExam && !this.courseAndExamIds) {
      //   if (!hideToast) {
      //     this.$message.info('请选择课程或考试');
      //   }
      //   return false;
      // }
      if (this.showCourse && this.courseIds.split(',').length > this.maxAllowableCourseCount) {
        if (!hideToast) {
          this.$message.info(`最多只能选择${this.maxAllowableCourseCount}门课程`);
        }
        return false;
      }
      // if (this.showExam && this.examIds.split(',').length > 5) {
      //   if (!hideToast) {
      //     this.$message.info('最多只能选择5门考试');
      //   }
      //   return false;
      // }
      // if (this.showCourseAndExam && this.courseAndExamIds.split(',').length > 5) {
      //   if (!hideToast) {
      //     this.$message.info('最多只能选择5门课程或考试');
      //   }
      //   return false;
      // }
      if (this.showOrg && !this.orgIds) {
        if (!hideToast) {
          this.$message.info('请选择组织机构');
        }
        return false;
      }
      let isError = true;

      if (this.showOrg && !this.orgParams) {
        for (const key in this.orgParams) {
          if (this.orgParams[key] !== '') {
            isError = false;
          }
        }
        if (!hideToast || isError) {
          this.$message.info('请选择店类型');
          return false;
        }
        return false;
      }
      if (this.showJob && !this.jobIds) {
        if (!hideToast) {
          this.$message.info('请选择岗位');
        }
        return false;
      }
      if (this.showEndTime && !this.endTime) {
        if (!hideToast) {
          this.$message.info('请选择截至日期');
        }
        return false;
      }
      if (this.showPeopleList && !this.peopleListId) {
        if (!hideToast) {
          this.$message.info('请选择人员列表');
        }
        return false;
      }
      return true;
    },

    search(hideToast) {
      if (!this.check(hideToast)) {
        return;
      }
      this.$emit('search');
    },
    reset() {
      this.resetQuery();
      this.$emit('reset');
    },
    exportExcel(hideToast) {
      if (!this.check(hideToast)) {
        return;
      }
      this.$emit('export');
    },
    cancel() {
      if (!this.showBack) {
        return;
      }
      this.$emit('cancel');
      if (!this.customBack) {
        this.$router.back();
      }
    },
    disabledDate(current) {
      if (
        this.routePath.search('/index/statistics/participation/student') >= 0
      ) {
        return (
          current &&
          current.isAfter(moment(), 'days')
        );
      } else {
        return (
          current &&
          !current.isBefore(moment(), 'days')
        );
      }
      // Can not select days before today and today
    },
  },
};
</script>

<style scoped lang="less">
.cus-selection {
  margin-top: 5px;
}
</style>
